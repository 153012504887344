.watermark {
  display: none;
  top: 50vh;
  z-index: -9;
  width: 50vw;
  page-break-after: always;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.04;
}

table.report-container {
  page-break-after: always;
  width: 100%;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

.header,
.header-space {
  height: 150px;
}

.header {
  position: fixed;
  top: 0;
  display: flex;
}

.noborder {
  border-style: hidden !important;
}

#report {
  border-collapse: collapse;
  width: 100%;
}

#report td,
#report th {
  border: 1px solid #ddd;
  padding: 4px 10px;
}

#report tr:nth-child(even) {
  background-color: rgba(178, 178, 178, 0.196);
}

#report tr:hover {
  background-color: #dddddd;
}

#report th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
  background-color: rgb(79, 94, 179);
  color: white;
}

div.page-footer {
  text-align: center;
  height: 60px;
  font-size: 10px;
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  width: 100%;
}

div.page-footer p {
  margin: 0;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

div.footer-info, div.page-footer {
  display: none;
  height: 60px;
  padding-top: 1em;
}
div.header-info {
  margin-bottom: 1em;
}


@media print {
  @page {
      size: A4;
      margin: 0mm 0mm 0mm 0mm;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .watermark {
      display: block;
      counter-increment: page;
      position: fixed;
  }
  div.page-footer, div.footer-info {
      display: block;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
}
}