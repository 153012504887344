* {
  font-family: Arial, Helvetica, sans-serif;
}

#invoice-POS {
  margin: 0;
  /* width: 84mm; */
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#invoice-POS h1 {
  font-size: 2.2em;
  color: #222;
}
#invoice-POS h2 {
  font-size: 1.7em;
}
#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
#invoice-POS p {
  font-size: 1em;
  line-height: 1.4em;
}

#invoice-POS #top {
  min-height: 100px;
}
#invoice-POS #mid {
  min-height: 80px;
}
#invoice-POS #bot {
  min-height: 50px;
}
#invoice-POS #top .logo {
  width: 84mm;
}

#invoice-POS .info {
  display: block;
  margin-left: 0;
}
#invoice-POS .title {
  float: right;
}
#invoice-POS .title p {
  text-align: right;
}
#invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}
#invoice-POS .tabletitle {
  font-size: 0.7em;
  border-bottom: 4px solid #222;
  border-top: 4px solid #222;
}
#invoice-POS .totaltitle {
  margin-top: 10pt;
}
.Rate {
  text-align: right;
  padding-right: 0.7em;
}
.item {
  text-align: left;
  padding-left: 0.7em;
}

.payment {
  text-align: right;
  padding-right: 0.7em;
}
.paymentAr {
  text-align: right;
  padding-right: 0.7em;
  font-size: 14pt;
}
.company {
  font-size: 1.3em;
  font-weight: bold;
}
.legal {
  text-align: center;
  font-size: 18pt;
  font-weight: bold;
  font-size: 0.8em;
}
.legalAr {
  text-align: center;
  font-size: 20pt;
  font-weight: bold;
  margin-bottom: 10pt;
}
.end {
  margin-top: 30pt;
  border-bottom: 4px solid #333;
  margin-bottom: 40px;
}
#invoice-POS .item {
  width: 42mm;
}

#invoice-POS .headitem {
  font-size: 1.3em;
  font-weight: bold;
  margin: 2px 0px 2px 0px;
}
#invoice-POS .headitemAr {
  font-size: 1.5em;
  font-weight: bold;
  margin: 2px 0px 2px 0px;
}

#invoice-POS .itemtextAr {
  font-size: 1.1em;
  /* font-weight: bold; */
  margin: 0;
  padding-left: 0.5em;
  padding-top: 5px;
}
#invoice-POS .itemtext {
  font-size: 1em;
  padding-left: 0.5em;
  margin: 0;
  padding-bottom: 5px;
}
#invoice-POS .itemamount {
  font-size: 1em;
  text-align: right;
  padding-right: 0.6em;
}
#invoice-POS #legalcopy {
  margin-top: 20mm;
}

